import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Close as CloseIcon } from '@mui/icons-material';
import classes from './DialogModel.module.css';
import SuccessIcon from 'components/SVG/SuccessIcon';
import WarningIcon from 'components/SVG/WarningIcon';
import RejectIcon from 'components/SVG/RejectIcon'
import { updateLoader, LoaderType } from '../../../features/loaderSlice';
import { resendVerificationLink } from 'services/apiHandler';
import ButtonOutlined from 'components/ButtonOutlined/ButtonOutlined';
import ButtonContained from 'components/ButtonContained/ButtonContained';
import { useDispatch } from 'react-redux';
import {
  FAILED,
  SESSION_EXPIRED_MESSAGE,
  SOMETHING_WENT_WRONG,
  SESSION_EXPIRED,
  SUCCESS,
  VERIFICATION_LINK_SENT_SUCCESFULLY_MESSAGE,
  VERIFICATION_LINK_SENT_FAILED_MESSAGE,
} from '../../../constants/apiStatus';
import { useTranslation } from 'react-i18next';

interface IDialogModelProps {
  headerTitleText: string;
  isOpen: boolean;
  handleClose?: () => void;
  handleNext?: () => void;
  handleCloseForce: () => void;
  body?: any;
  secondaryText?: string;
  modelType: LoaderType;
  handleConfirmCallBack?: () => void;
  handleConfirmFinalRoundCallBack?: () => void;
  buttonText?: string;
  hideConfirmBtn?: boolean;
}

const DialogModel = ({
  headerTitleText,
  isOpen,
  handleClose,
  handleCloseForce,
  body = '',
  modelType,
  handleConfirmCallBack,
  handleConfirmFinalRoundCallBack,
  secondaryText,
  buttonText,
  hideConfirmBtn = false,
}: IDialogModelProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const renderIcon = () => {
    switch (modelType) {
      case LoaderType.success:
        return <SuccessIcon width={50} height={50} />;
      case LoaderType.error:
        return <WarningIcon width={50} height={50} />;
      case LoaderType.confirm:
        return <WarningIcon color={'#e1c340'} width={50} height={50} />;
      case LoaderType.action:
        return <WarningIcon color={'#e1c340'} width={50} height={50} />;
      case LoaderType.warning:
        return <WarningIcon color={'#BD4334'} width={50} height={50} />;
      case LoaderType.test:
        return <WarningIcon color={'#BD4334'} width={50} height={50} />;
      case LoaderType.approveInbox:
        return <WarningIcon color={'#F2BE42'} width={50} height={50} />;
      case LoaderType.rejectInbox:
        return <RejectIcon color={'#FF2626'} width={50} height={50} />;
      case LoaderType.confirmRFI:
        return <WarningIcon color={'#e1c340'} width={50} height={50} />;
      case LoaderType.rfqNextRoundConfirm:
        return <WarningIcon color={'#e1c340'} width={50} height={50} />;
      case LoaderType.confirmAward:
        return <WarningIcon color={'#e1c340'} width={50} height={50} />;
      default:
        return null;
    }
  };

  const resendVerification = async () => {
    const payload = {
      userId: localStorage.getItem('userId'),
      email: localStorage.getItem('email'),
    };
    await resendVerificationLink(payload)
      .then((response: any) => {
        if (response.data.status) {
          dispatch(
            updateLoader({
              showSpinner: true,
              body: VERIFICATION_LINK_SENT_SUCCESFULLY_MESSAGE,
              headerTitleText: SUCCESS,
              modelType: LoaderType.success,
            })
          );
        } else {
          dispatch(
            updateLoader({
              showSpinner: true,
              body: response.data.message,
              headerTitleText: FAILED,
              modelType: LoaderType.error,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate('/login');
          localStorage.clear();
          dispatch(
            updateLoader({
              showSpinner: true,
              body: SESSION_EXPIRED_MESSAGE,
              headerTitleText: SESSION_EXPIRED,
              modelType: LoaderType.error,
            })
          );
        } else {
          dispatch(
            updateLoader({
              showSpinner: true,
              body: VERIFICATION_LINK_SENT_FAILED_MESSAGE,
              headerTitleText: SOMETHING_WENT_WRONG,
              modelType: LoaderType.error,
            })
          );
        }
      });
  };

  return (
    <Dialog
      sx={styles}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <div
        style={{ width: '100%', height: '1rem' }}
        className={classes[modelType]}
      ></div>
      <DialogTitle id='alert-dialog-title'>
        {/* <Typography variant='h6'>{headerTitleText}</Typography> */}
        <IconButton
          onClick={handleCloseForce}
          sx={{ position: 'absolute', right: '.1rem', top: '1rem' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            overflowX:'initial !important'
          }}
        >
          <Box sx={{ height: '100%', paddingTop: '10px' }}>{renderIcon()}</Box>
          <Box sx={{ height: '100%', paddingLeft: '1.5rem' }}>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
              {headerTitleText}
            </Typography>
            <Typography variant='body1'>{body}</Typography>
            <Typography variant='body1' sx={{ color: 'grey' }}>
              {secondaryText}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      {modelType === LoaderType.confirm && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <Button
            onClick={handleConfirmCallBack}
            variant='contained'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('Yes, Confirm')}
          </Button>
        </DialogActions>
      )}

      {modelType === LoaderType.action && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        ></DialogActions>
      )}

      {modelType === LoaderType.warning && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <Button
            onClick={resendVerification}
            variant='contained'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('Resend Verification Link')}
          </Button>
        </DialogActions>
      )}

      {modelType === LoaderType.test && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <Button
            onClick={handleConfirmCallBack}
            variant='contained'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('Yes, confirm')}
          </Button>
        </DialogActions>
      )}
      {modelType === LoaderType.approveInbox && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <Button
            onClick={handleConfirmCallBack}
            variant='contained'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('confirm & Send')}
          </Button>
        </DialogActions>
      )}
      {modelType === LoaderType.rejectInbox && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <Button
            onClick={handleConfirmCallBack}
            variant='contained'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('Submit')}
          </Button>
        </DialogActions>
      )}
      {modelType === LoaderType.confirmRFI && (
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
            <ButtonOutlined
              onClick={handleCloseForce}
              variant='contained'
              sx={{
                textTransform: 'none',
              }}
            >
              {t('Cancel')}
            </ButtonOutlined>

            <ButtonContained
              onClick={handleConfirmCallBack}
              variant='contained'
              type='submit'
              sx={{
                textTransform: 'none',
              }}
            >
              {`${t(buttonText ? buttonText : 'Confirm & Launch')}`}
            </ButtonContained>
          </div>
        </DialogActions>
      )}

      {modelType === LoaderType.rfqNextRoundConfirm && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingBottom: '1rem',
          }}
        >
          <Button
            onClick={handleConfirmFinalRoundCallBack}
            variant='contained'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('Final Round')}
          </Button>

          {!hideConfirmBtn && (
            <Button
              onClick={handleConfirmCallBack}
              variant='contained'
              sx={{
                textTransform: 'none',
              }}
            >
              {t('Yes, Confirm')}
            </Button>
          )}
        </DialogActions>
      )}
      {modelType === LoaderType.confirmAward && (
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem',
          }}
        >
          <Button
            onClick={handleConfirmCallBack}
            variant='contained'
            sx={{
              textTransform: 'none',
            }}
          >
            {t('confirm & Send')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const styles = {
  '& .MuiDialog-paper': {
    width: '30%',
    maxWidth: '100%',
    height: 'fit-content',
    borderRadius: '8px',
  },
};

export default DialogModel;
